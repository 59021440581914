import styled from "styled-components";
import { Link } from "gatsby";

export const Title = styled.h1`
  max-width: 628.62px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 58px;
  /* or 112% */
  width: 80%;
  margin-bottom: 60px;
  /* margin-top: 50px;
   */
  margin-top: 0;

  display: flex;
  align-items: center;
  text-align: center;

  color: #111111;

  @media (max-width: 800px) {
    margin-top: 32px;
  }

  @media (max-width: 480px) {
    font-size: 32px;
    line-height: 36px;
  }
`;

export const Paragraph = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  ${(props) => props?.margin && `margin-top: 60px;`}
  ${(props) => props?.marginBottom && `margin-bottom: 60px;`}
    width: 95%;
  @media (max-width: 480px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    width: 296px;
  }

  a {
    display: inline;
    color: #007de6;
  }
  color: #222222;
`;
export const Style = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  overflow: hidden;
  background: #ffffff;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 880px;
    box-sizing: border-box;
    margin: 0px 24px;
    padding: 32px 0px;
    span {
      @media (max-width: 480px) {
        font-size: 18px !important;
      }
    }

    .button-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 0px;
      width: 100%;
      height: max-content;
      flex: none;
      order: 2;
      flex-grow: 0;
      margin: 48px 0px;

      @media (max-width: 760px) {
        flex-direction: column;
      }

      .button-link {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 24px;
        border: 2px solid #007de6;
        box-sizing: border-box;
        border-radius: 60px;
        width: 310px;
        height: 60px;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 16px;
        text-decoration: none;

        @media (max-width: 760px) {
          margin: 16px 0px;
        }

        @media (max-width: 480px) {
          width: 280px;
          font-size: 16px;
        }

        .button-text {
          width: max-content;
          height: 27px;
          font-family: Work Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 27px;
          text-align: center;
          color: #007de6;
          flex: none;
          order: 0;
          flex-grow: 0;
          margin: 0px 10px;
        }
      }
    }
  }
`;

export const YouOrder = styled.div`
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #222222;
  min-width: 401px;

  border: 1px solid #dcdcdc;
  padding: 32px 64px;
  max-width: 735px;
  width: 90%;

  @media (max-width: 480px) {
    font-size: 24px;
    line-height: 32px;
  }

  svg {
    width: 169px;
  }
`;

export const ArticleHolder = styled.div`
  border: 1px solid #dcdcdc;
  border-top: none;
  padding: 32px 64px;
  max-width: 735px;
  width: 90%;
  background: #fbfbfb;
  display: flex;
  flex-direction: column;
  min-width: 401px;
  ${(props) => props?.mobile && `display: none;`}

  @media (max-width: 480px) {
    ${(props) => props?.desktop && `display: none;`}
    ${(props) => props?.mobile && `display: flex;`}
  }
`;
export const Article = styled.div`
  display: flex;
  width: max-content;
`;

export const Orders = styled.div`
  background: #00ca42;
  border-radius: 4px;
  height: 21px;
  min-width: 29px;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  margin-right: 8px;
`;

export const ArticleTitle = styled.div`
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #222222;
`;

export const AtriclePrice = styled.div`
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #222222;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.margin && "  margin: 16px 0; "}
`;
export const Renewal = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  margin: 16px 0;
  display: flex;
  align-items: center;

  color: #8d8d8d;
`;

export const LinkAccount = styled(Link)`
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #0000ee;
`;

export const ArticleText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
`;

export const Tax = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #8d8d8d;
`;
export const Reference = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
`;

export const Saved = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #00ca42;
`;

export const Redirect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  overflow: hidden;
  margin-top: 24px;

  svg {
    transform: scale(5);
    margin-bottom: 96px;
    stroke: #e44331;
  }

  div {
    font-size: 48px;
  }
`;
