import React from "react";

import { useEffect } from "react";
import { navigate } from "gatsby";
import { productInfo } from "../helpers/PriceCalculator";

import Logo from "../images/sharedImages/pdf_logo.svg";
import PDFLogo from "../images/sharedImages/Logo_Pro.svg";

import {
  Article,
  ArticleHolder,
  ArticleText,
  ArticleTitle,
  AtriclePrice,
  LinkAccount,
  Orders,
  Paragraph,
  Redirect,
  Reference,
  Row,
  Saved,
  Style,
  Tax,
  Title,
  YouOrder,
} from "../components/Purchased/purchased.styled";

import Seo from "../components/shared/seo";

import { useIntl } from "gatsby-plugin-react-intl";
import Layout from "../components/shared/layout";

const Purchased = (props) => {
  const [product, setProduct] = React.useState("PDF Creator");
  const [download, setDownload] = React.useState("");
  const [pdfpro, setPdfpro] = React.useState(false);

  // after successful purchase it will start download automatically
  const delayedDownload = (link) => {
    navigate(link);
  };
  const intl = useIntl();

  const purchaseData = props.location.state?.purchase;
  console.log("Purchase Data: ", purchaseData);

  const productPath = purchaseData?.items[0]?.product;

  const realPath = purchaseData?.items[0]?.product;
  const addOn = purchaseData?.items[1]?.product;

  useEffect(() => {
    if (realPath.includes("pdf-pro")) {
      setProduct("PDF PRO");
      setDownload("https://gtw.pdfpro10.com/product/pro/pdfpro_nix/trial");
      setPdfpro(true);
    }

    if (realPath.includes("pdf-pro-upgrade")) {
      setProduct(`PDF PRO Upgrade`);

      setPdfpro(true);
    }

    if (realPath.includes("pdf-creator-maintenance")) {
      setProduct("PDF Creator Maintenance");
      setDownload("https://track.pdfpro10.com/product/normal/pdfcreator/trial");
    }

    if (realPath.includes("pdfcreator")) {
      setDownload("https://track.pdfpro10.com/product/normal/pdfcreator/trial");
    }
    if (!purchaseData) {
      navigate("/login", { replace: true });
    } else {
      // GA4 - Custom Event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "purchase",
        eventName: "purchase",
        transaction_id: purchaseData.reference,
        value: purchaseData.total,
        currency: purchaseData.currency,
        items: purchaseData.items,
      });
    }
  }, [purchaseData]);

  useEffect(() => {
    //&& !purchaseData
    console.log("Download: ", download);

    if (download !== "") delayedDownload(download);
  }, [download]);

  const firstFulfillment = purchaseData?.items[0]?.fulfillments;
  const firstFulfillmentKey = firstFulfillment
    ? Object?.keys(firstFulfillment)[0]
    : ""; // Gets the first key name
  const licenses = purchaseData?.items[0]?.fulfillments[firstFulfillmentKey];
  console.log(
    "Licenses: ",
    purchaseData?.items[0]?.fulfillments[firstFulfillmentKey]
  );

  if (!purchaseData) {
    return (
      <Redirect>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M14 16l6-6-6-6" />
          <path d="M4 21v-7a4 4 0 0 1 4-4h11" />
        </svg>
        <div>Redirecting...</div>
      </Redirect>
    );
  }

  console.log("Product Price: ", productInfo(productPath, 1));

  return (
    <Layout>
      <Style>
        <Seo title="Purchased Page" noRobotsIndex={true} />
        <div className="container">
          <Title>Thank you for your purchase!</Title>
          {download !== "" && (
            <Paragraph marginBottom>
              <div>
                You may download your copy of {product.replace("Upgrade", "")}{" "}
                <a className="tracker" href={download}>
                  here
                </a>
                .{" "}
              </div>
            </Paragraph>
          )}
          <YouOrder>
            {" "}
            Your Order
            {!pdfpro && <Logo />}
            {pdfpro && <PDFLogo />}
          </YouOrder>
          <ArticleHolder>
            <Row>
              <Article>
                <Orders>x{purchaseData?.items[0]?.quantity}</Orders>
                <ArticleTitle>{product}</ArticleTitle>
              </Article>
              <AtriclePrice>
                {productInfo(productPath, 1)?.priceFormatted}
              </AtriclePrice>
            </Row>
            {/* <Renewal>
            {!productPath.includes("perpetual") &&
              `Subscription renews every ${dateValue} ${unit}. Next charge ${RenewalDate}(
            ${productInfo(realPath, 1)?.totalPrice}
            ).`}
            {productPath.includes("perpetual") && `One time fee`}
          </Renewal> */}
            {licenses && <br />}
            <ArticleText>
              License Key{licenses?.length > 1 ? "s" : ""}
            </ArticleText>
            {licenses?.map((license) => {
              return <ArticleText>{license.license}</ArticleText>;
            })}
            <br />
            <LinkAccount
              to={`https://${intl.formatMessage({
                id: "FSDomain",
              })}/account`}
              target="_blank"
            >
              Full Terms and Management
            </LinkAccount>
          </ArticleHolder>
          {addOn?.includes("eds") && (
            <ArticleHolder>
              <Row>
                <Article>
                  <Orders>x1</Orders>
                  <ArticleTitle>Extended Download Service</ArticleTitle>
                </Article>
                <AtriclePrice>
                  {productInfo(productPath, 1)?.addOnPrice}
                </AtriclePrice>
              </Row>
            </ArticleHolder>
          )}

          <ArticleHolder desktop>
            <Row>
              <ArticleText>
                Changes will appear on your bills as PP*FS {product} Soft
              </ArticleText>
              <Tax>Tax: {purchaseData?.taxDisplay}</Tax>
            </Row>
            <Row margin>
              <Article>
                <Reference>Order Reference:&nbsp;</Reference>
                <LinkAccount
                  to={`https://${intl.formatMessage({
                    id: "FSDomain",
                  })}/account`}
                  target="_blank"
                >
                  {purchaseData?.reference}
                </LinkAccount>
              </Article>
              {/* <Saved>You Saved: {purchaseData?.discountWithTaxDisplay}</Saved> */}
            </Row>
            <Row>
              {" "}
              <LinkAccount
                to={`https://${intl.formatMessage({
                  id: "FastSpring",
                })}/account/order/${purchaseData?.reference}/invoice/`}
                target="_blank"
              >
                View invoice
              </LinkAccount>
              <AtriclePrice> Total: {purchaseData?.totalDisplay}</AtriclePrice>
            </Row>
          </ArticleHolder>
          <ArticleHolder mobile>
            <Row>
              <Tax>Tax: {purchaseData?.taxDisplay}</Tax>
            </Row>
            <Row margin>
              <Saved>You Saved: {purchaseData?.discountWithTaxDisplay}</Saved>
            </Row>
            <Row>
              <AtriclePrice> Total: {purchaseData?.totalDisplay}</AtriclePrice>
            </Row>
            <Row margin>
              <ArticleText>
                Changes will appear on your bills as PP*FS {product} Soft
              </ArticleText>
            </Row>
            <Row>
              <Article>
                <Reference>Order Reference:&nbsp;</Reference>
                <LinkAccount
                  to={`https://${intl.formatMessage({
                    id: "FSDomain",
                  })}/account`}
                  target="_blank"
                >
                  {purchaseData?.reference}
                </LinkAccount>
              </Article>
            </Row>
            <Row margin>
              {" "}
              <LinkAccount
                to={`https://${intl.formatMessage({
                  id: "FastSpring",
                })}/account/order/${purchaseData?.reference}/invoice/`}
                target="_blank"
              >
                View invoice
              </LinkAccount>
            </Row>
          </ArticleHolder>
        </div>
      </Style>
    </Layout>
  );
};

export default Purchased;
